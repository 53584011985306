











































































  import { Validator } from '@/constant/Mixins';
  import { Toaster } from '@/constant/Toaster';
  import { ServerResponse } from '@/services/response.types';
  import { AddressBookModel, UserModel } from '@/store/auth/types';
  import { BenefitCaryRelationModel, PaymentProductKecelakaanDiriConfirmationModel } from '@/store/product/kecelakaandiri/types';
  import { ProductModel } from '@/store/product/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'personalKecelakaanDiri',
    components: {
      PaymentOptions: () => import('@/components/payment/paymentOptions.vue'),
    },
  })
  export default class personalMobil extends Mixins(Validator) {
    @Action('CheckuserAuth', { namespace: 'auth' }) CheckuserAuth!: () => Promise<ServerResponse>;
    @Action('GetPaymentConfirmProduct', { namespace: 'product/kecelakaandiri' }) GetPaymentConfirmProduct!: (quotation_id: string) => Promise<ServerResponse>;
    @Action('CheckoutProduct', { namespace: 'product/kecelakaandiri' }) CheckoutProduct!: (quotation_id: string) => Promise<ServerResponse>;
    @Action('GetAddresBook', { namespace: 'auth' }) GetAddresBook!: () => Promise<ServerResponse>;

    @State('confirmation', { namespace: 'product/kecelakaandiri' }) confirmation!: PaymentProductKecelakaanDiriConfirmationModel;
    @State('address', { namespace: 'auth' }) addressList!: AddressBookModel[];

    public get user(): UserModel {
      return this.confirmation.user;
    }

    public get relation(): BenefitCaryRelationModel {
      return this.confirmation.relation;
    }

    public get product(): ProductModel {
      return this.confirmation.product;
    }

    public get address(): AddressBookModel {
      return this.addressList[0] ?? new AddressBookModel();
    }

    public showModal = false;
    public summary: { label?: string; value: any; label_class?: string; value_class?: string; row_class?: string; label_colspan?: string; value_colspan?: string; show_label?: boolean; html_value?: boolean }[] = [];
    public transaction_id: string = null;
    public confirmed = false;

    public created(): void {
      this.GetAddresBook();
    }

    public mounted(): void {
      this.GetPaymentConfirmProduct(this.$route.query.q as string).then((res) => {});
    }

    /**
     * OnSubmit
     */
    public OnSubmit() {
      if (this.confirmed) {
        this.CheckoutProduct(this.$route.query.q as string).then((res) => {
          // Toaster.Make(res.messages, { variant: 'success', title: 'Payment Complete!' });
          // this.$router.push({ name: 'transaction-history' });

          let summ = res.data.summary;

          this.summary = [];
          this.summary.push({ label: 'Perusahaan', value: summ.provider_name });
          this.summary.push({ label: 'Produk', value: this.product.product_name });
          this.summary.push({ label: 'Premi Dasar', value: this.formatCurrency(summ.basic_premi) });
          this.summary.push({ label: 'Premi Tambahan', value: this.formatCurrency(summ.additional_premium ?? 0) });
          this.summary.push({ label: 'Diskon', value: this.formatCurrency(summ.discount_amount ?? 0) });
          this.summary.push({ label: 'Administrasi', value: this.formatCurrency(summ.admin_fee ?? 0) });
          this.summary.push({ label: 'Total Tagihan', value: this.formatCurrency(summ.total ?? 0), row_class: 'bg-primary text-white' });
          this.summary.push({ show_label: false, value_colspan: '2', html_value: true, value: `<p><b>Alamat Pengiriman Polis</b></p><p>${summ.shipping_address}</p>` });

          this.transaction_id = res.data.enc_trx_id;

          this.showModal = true;
        });
      } else Toaster.Warning('Please Confirm by checking the checkbox!', { title: 'Need Confirmation' });
    }
  }
